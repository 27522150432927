<template>
    <div class="car">
        <div class="car_img" v-bind:style="{ backgroundImage: 'url(' + car.image_medium + ')' }"></div>
      <div class="car_info">
        <h5>{{car.name}}</h5>
        <p class="paragraph">cena:{{car.price}}</p>
        <button @click="toggleModal(car.id)" class="prime_button">Více informací</button>
      </div>
    </div>
</template>

<script>
export default {
    name: 'CarCard',
    props:{
        car: Object
    },
    methods:{
    toggleModal(id) {
      this.$store.commit('TOGGLE_MODAL')
      this.$router.push('prodej/'+id)
    }
    }
    
}
</script>

<style scoped>

.car{
  width: 100%;
  height: 100%;
  background: #35353b;
  display: inline-block;
  margin-top: 4.5rem;
  -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  border-radius: 5px
}

.car_img{
  width: 50%;
  height: 200px;
  display: inline-block;
  background-position: center;
  background-size: cover; 
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  background-color: #1e1e1f
}

.car_info{
  width: 50%;
  float: right;
  height: 200px;
  display: inline-block;
}

.car h5{
  font-size: 1.7rem;
  color:#910C0C;
  font-weight: 100;
  padding: 1.5rem 1.5rem 0rem 1.5rem
}

.car p{
  margin-top: -50px;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  font-weight: 100
}

.car button{
  margin: 0rem 1.5rem 1.5rem 1.5rem;
  padding: 0.5rem 1rem;
}

.car button:hover .car{
  background: green;
}

@media (max-width: 700px) {
.car_img{
  width: 100%;
  height: 150px;
  display: inline-block;
  background-position: center;
  background-size: cover; 
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  background-color: #1e1e1f
}
.car_info{
  width: 100%;
  height: 200px;
  display: inline-block;
}
}
</style>
