import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalVisible: false,
    texts:[],
    sluzby: [
      {id:'opravy',headline:'Opravy', description: 'Z oblasti oprav nabízíme: -karosářské opravy -mechanické opravy -výměny autoskel -výměny olejů Castrol s normami BMW a olejů BMW -opravy motorů BMW -opravy a servis automatických převodovek vozů BMW -opravy elektroniky automobilu -řešení pojistných událostí', more_text:'Ano'},
      {id:'nahrada',headline:'Náhradní vozidlo BMW', description: 'Nabízíme Vám zapůjčení náhradního vozidla BMW po dobu opravy Vašeho vozu.', more_text:''},
      {id:'dovoz',headline:'Dovoz a prodej automobilů BMW', description: 'Dovoz ojetých automobilů BMW z Německa.Dovoz automobilů BMW na zakázku. Výsledky naší práce, mezi nimiž jsou i automobily námi dovezené, si můžete prohlédnout zde.', more_text:'dovoz'}
    ],
    sale_categories:[],
    sale_items: [],
    service_categories:[],
    service_items:[],
    news:[]
  },
  mutations: {
    MODAL(state,data){
      state.modalVisible = data
    },
    TOGGLE_MODAL(state){
      state.modalVisible = !state.modalVisible
    },
    LOAD_TEXTS(state,data){
      state.texts = data
    },
    ADD_SALE_CATEGORY(state,data){
      state.sale_categories.push(data)
    },
    ADD_NEWS(state,data){
      state.news.push(data)
    },
    ADD_SALE_ITEM(state,data){
      state.sale_items.push(data)
    },
    ADD_SERVICE_CATEGORY(state,data){
      state.service_categories.push(data)
    },
    ADD_SERVICE_ITEMS(state,data){
      state.service_items.push(data)
    }
  },
  actions: {
    LOAD_TEXTS (context) {
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/pages/15',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
            context.commit('LOAD_TEXTS',response.data.acf)        
        ))
    },
    LOAD_SALE_CATEGORIES (context) {
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/sale_categories',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
          response.data.forEach(element => {
            context.commit('ADD_SALE_CATEGORY',{id:element.id, count:element.count, name:element.name, description:element.description})
          }))
        )
    },
    LOAD_SERVICES_CATEGORIES (context) {
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/service_categories',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
          response.data.forEach(element => {
            context.commit('ADD_SERVICE_CATEGORY',{id:element.id, count:element.count, name:element.name, description:element.description})
          }))
        )
    },
    LOAD_SALE_ITEMS (context) {     
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/sales',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
          response.data.forEach(element => {
            var data = {id:element.id, category:element.sale_categories[0], images:[], name:element.title.rendered, text:element.acf.text,price:element.acf.price}
            if (element.better_featured_image) {
              data['image_medium'] = element.better_featured_image.media_details.sizes.medium.source_url
            }
            if (element.acf.image_1) {
              data['images'].push(element.acf.image_1.sizes.medium_large)
            }
            if (element.acf.image_2) {
              data['images'].push(element.acf.image_2.sizes.medium_large)
            }
            if (element.acf.image_3) {
              data['images'].push(element.acf.image_3.sizes.medium_large)
            }
            if (element.acf.image_4) {
              data['images'].push(element.acf.image_4.sizes.medium_large)
            }
            context.commit('ADD_SALE_ITEM',data)
          })
          )
        )

    },
    LOAD_SERVICES_ITEMS (context) {     
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/services',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
          response.data.forEach(element => {
            var data = {id:element.id, category:element.service_categories[0], name:element.title.rendered, short_text:element.acf.short_text, long_text:element.acf.long_text,}
            if (element.better_featured_image) {
              data['image_medium_large'] = element.better_featured_image.media_details.sizes.medium_large.source_url
            }
            context.commit('ADD_SERVICE_ITEMS',data)
          })
          )
        )

    },
    LOAD_NEWS (context) {     
      Axios
        .get('https://www.autocermak.cz/admin/wp-json/wp/v2/news',{headers:{'Cache-Control': 'no-cache'}})
        .then(response => (
          response.data.forEach(element => {
            var data = {id:element.id, text:element.title.rendered, date:element.date.split("T")[0]}
            context.commit('ADD_NEWS',data)
          })
          )
        )
  
    },
  }
  
})

