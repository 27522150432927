<template>
    <div v-if="service" id="service_view">
        <h1 class="underline_header">{{service.name}}</h1>
        <p>{{service.long_text}}</p>
        <img class="animated slideInUp delay-05s faster" v-if="service.image_medium_large" v-bind:src="service.image_medium_large" alt="Obrázek ke službě">
    </div>
</template>

<script>
export default {
    name: 'ServiceVue',
    computed:{
        service(){
            return this.$store.state.service_items.filter(item => item.id.toString() == this.$route.params.what)[0]
        }
    }
}
</script>

<style scoped>
    #service_view{
        height: 100%;
        width: 100%;
        padding: 2.5%;
    }
    .underline_header{
        margin: -25px 0px 0px 0px
    }
    #service_view p{
        margin-top: 20px;
        font-weight: 100
    }
    #service_view img{
        width: 50%;
        -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        border-radius: 5px;
        margin-right: 3%;
    }

    @media (max-width: 700px) {
        #service_view img{
        width: 90%
        }
    }
</style>
