<template>
    <div class="sluzba">
              <h5>{{sluzba.name}}</h5>
              <p class="paragraph">{{sluzba.short_text}}</p>
              <button v-if="sluzba.long_text" class="prime_button" @click="toggleModal(sluzba.id)">Více informací</button>
    </div>
</template>

<script>
export default {
    name: 'ServiceCard',
    props:{
        sluzba: Object
    },
    methods:{
    toggleModal(id) {
      this.$store.commit('TOGGLE_MODAL')
      this.$router.push('sluzba/'+id)
    }
  },
    
}
</script>

<style scoped>

.sluzba{
  width: 100%;
  height: auto;
  background: #35353b;
  display: inline-block;
  margin-top: 4.5rem;
  -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  border-radius: 5px
}

.sluzba h5{
  font-size: 2rem;
  color:#910C0C;
  font-size: 1.7rem;
  padding: 2rem 2rem;
  font-weight: 100
}

.sluzba p{
  padding: 0px 2rem 1.5rem;
  margin-top: -20px;
}

.sluzba button{
  display: block;
  float:right;
  margin: 2rem;
}
</style>
