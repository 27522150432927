<template>
<div>
    <transition name="fadeBg">
        <div v-if="isVisible" @click="close_modal()" id="bg"></div>
    </transition>
    <transition name="close">
        <div v-if="isVisible" id="modal" >
            <div id="top">
                <button class="animated fadeIn delay-1s faster" @click="close_modal()" id="close_btn"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">
<line fill="none" stroke="#910C0C" stroke-miterlimit="10" x1="0" y1="0" x2="30" y2="30"/>
<line fill="none" stroke="#910C0C" stroke-miterlimit="10" x1="0" y1="30" x2="30" y2="0"/>
</svg>
</button>
            </div>
            <div id="scroll">
                <router-view></router-view>
            </div>
        </div>
            </transition>
</div>
</template>

<script>
export default {
    name: 'modal',
    computed:{
        isVisible(){
            var isVisible = this.$store.state.modalVisible
            if (isVisible) {
                document.body.style.overflow = 'hidden';
            }
            else{
                document.body.style.overflow = 'auto';
                this.$router.push('/')
            }
            return isVisible
        },
    },
    methods:{
        close_modal(){
            this.$store.commit('TOGGLE_MODAL')
        } 
    }
}
</script>

<style scoped>

/* Animation */
.close-enter-active, .close-leave-active {
  transition: all 1s;
  -webkit-backdrop-filter: blur(5px);

}
.close-enter, .close-leave-to {
  transform: translateX(1000px)
}

.fadeBg-enter-active {
  transition: all 1s

}
.fadeBg-leave-active {
  transition: all 0.5s

}
.fadeBg-enter, .fadeBg-leave-to {
  opacity: 0;
  -webkit-backdrop-filter: blur(0px);
}

#bg{
    background: rgba(92, 92, 92, 0.5);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    z-index: 30;
    cursor: pointer;
}
#modal{
    position: fixed;
    background: #29282E;
    width: 30%;
    margin: 5vh 5% 5vh 60%;
    padding: 5% 0% 5% 5%;
    height: 70vh;
    border-radius: 8px;
    -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.9);
    -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.9);
    box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.9);
    z-index: 31;
}

#top{
    width: 25%;
    position: fixed;
    display: block;
    z-index: 1200;
    height: 1px
}

#close_btn{
    float: right;
    height: 45px;
    width: 45px;
    background: none;
    border-radius: 50%;
    border: none;
    padding: 10px;
    transition: transform 200ms;
    display: block
}

#close_btn svg{
    width: 100%;
    height: 100%;
}

#close_btn:hover{
    border: 1px solid #910C0C;
    transform: scale(1.3)
}
#scroll{
    height: 100%;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
}

@media (max-width: 700px) {
  #modal{
      height: 85vh;
      width: 85%;
      margin: 5%;
  }
  #top{
      width: 80%
  }
  #scroll{
	  width: 100%
  }
  #close_btn:hover{
      border: none;
  }
.close-enter, .close-leave-to {
  transform: translateX(500px)
}
}

</style>
