<template>
    <div v-if="sale" id="sale_view">
        <h1 class="underline_header">{{sale.name}}</h1>
        <p>{{sale.text}}</p>
        <span class="price">Cena: </span><span class="price">{{sale.price}}</span>
        <div id="gallery" v-if="sale.images">
            <span>Galerie:</span><br>
            <a v-for="(image) in sale.images" :key="image" v-bind:href="image"><img class="animated fadeInUp delay-1s faster" v-bind:src="image" alt="prodej"></a>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'saleVue',
    computed:{
        sale(){
            return this.$store.state.sale_items.filter(item => item.id.toString() == this.$route.params.what)[0]
        }
    }
}
</script>

<style scoped>
    #sale_view{
        height: 100%;
        width: 100%;
        padding: 2.5%;
    }
    .underline_header{
        margin: -25px 0px 0px 0px
    }

    .price{
        font-size: 1.3rem
    }
    .price:first-of-type{
        color: #910C0C
    }

    #sale_view p{
        margin-top: 20px;
        font-weight: 100;
        white-space: pre-line;
        font-size: 1.3rem
    }
    #sale_view img{
        width: 45%;
        -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
        border-radius: 5px;
        margin-right: 3%;
        translate: transform 1s
    }
    #gallery a img:hover{
        transform: scale(1.1);
    }

    #gallery{
        margin-top: 50px;
        width: 100%;
    }

    #gallery span{
        font-size: 1.3rem
    }

    #gallery a img{
        margin-top: 50px
    }

    @media (max-width: 700px) {
        #sale_view img{
        width: 90%
        }
        #sale_view img{
            transform: scale(1);
        }
        #gallery a img{
        margin-top: 20px
    }
    }
</style>
