<template>
	<div>
    <div id="header_menu" class="animated slideInDown fast">
        <ul>
            <a href="#O_nas"><li><span>O nás</span></li></a>
            <a href="#Sluzby"><li><span>Služby</span></li></a>
            <a href="#"><li><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="59px" height="30px" viewBox="0 0 595 410.022" enable-background="new 0 0 595 410.022" xml:space="preserve">
<g>
	<polygon fill="#FFFFFF" points="4.637,293.87 264.961,83.61 264.961,164.067 101.541,293.87 	"/>
	<polygon fill="#FFFFFF" points="264.602,180.516 264.602,269.555 150.89,269.196 	"/>
	<path fill="#FFFFFF" d="M431.596,211.986c14.304,0,28.964-0.36,40.406-3.579l-0.356,60.433h-81.887
		c-166.639-4.646-165.922-177.721,0-184.513h31.825v40.765l50.418-40.408v56.142c-10.727-2.503-27.535-4.293-40.406-4.293
		c-47.918,0-81.531,17.522-81.531,38.264C350.064,195.892,384.752,212.342,431.596,211.986"/>
	<path fill="#FFFFFF" d="M123.712,325.339l20.025-44.698h1.071h1.431h2.503l19.667,44.698h-3.574h-2.861h-2.145h-3.576
		c-1.431-3.935-2.859-8.583-5.008-13.23h-16.09c-1.072,2.501-1.786,4.289-2.503,5.363c-0.356,1.072-1.074,2.503-1.431,4.29
		c-0.356,1.791-1.071,2.862-1.071,3.577h-1.788h-1.789h-1.072H123.712z M149.816,307.817l-6.079-14.659l-6.795,14.659H149.816z
		 M170.556,308.889v-27.533h5.008h5.364v25.032c0,5.006,0.713,8.939,2.143,11.084c2.147,2.858,5.724,4.648,10.016,4.648
		c2.502,0,4.647-0.358,6.078-0.716c1.431-0.714,2.861-2.146,4.29-3.575c2.146-2.502,3.576-7.509,3.576-14.663v-21.811h1.073h1.431
		h1.431h1.786v23.601c0,8.227-1.786,13.945-5.363,16.448c-1.072,1.072-2.503,2.148-3.934,2.86c-1.429,0.718-3.576,1.074-5.361,1.788
		c-1.79,0.358-4.293,0.358-6.439,0.358c-7.15,0-13.231-1.787-16.804-5.007C171.987,318.544,170.556,314.254,170.556,308.889
		 M229.202,325.339v-39.693c-4.65,0-9.3,0.356-13.232,1.074v-0.718c0-0.356,0.357-0.713,0.357-0.713v-0.715V283.5v-1.071
		c0-0.358-0.357-0.716-0.357-1.072h36.116c0,0.714-0.357,2.144-0.357,3.219c0,0.715,0.357,1.787,0.357,2.146
		c-6.08-0.718-10.014-1.074-12.516-1.074v39.693h-3.219h-2.502h-2.146H229.202z M249.94,303.884v0.714
		c0,5.723,1.788,10.369,4.65,13.59c3.216,3.217,6.438,5.365,10.726,6.795c3.935,1.07,7.867,1.429,10.729,1.429
		c8.223,0,15.018-2.146,20.383-6.081c5.363-3.933,7.866-9.653,7.866-16.805c0-4.293-1.072-8.227-3.22-11.801
		c-1.786-3.218-5.005-6.436-8.94-8.582c-3.933-2.146-9.297-3.218-15.018-3.218c-6.08,0-11.085,1.431-15.377,3.574
		c-3.934,2.503-7.149,5.365-8.939,8.941C251.014,296.018,249.94,299.952,249.94,303.884 M292.135,302.455
		c0,4.289-1.43,9.296-3.574,13.943c-2.146,4.293-6.437,6.436-12.159,6.436c-2.503,0-5.005-0.713-7.15-2.143
		c-1.788-1.073-3.578-3.219-5.007-5.724c-1.431-2.503-2.145-5.718-2.145-9.295c0-3.934,0.358-7.511,1.431-10.728
		c0.714-3.22,2.861-6.08,5.004-7.868c2.503-2.146,5.364-3.219,8.939-3.219c4.293,0,8.226,1.788,10.729,5.007
		C290.705,292.441,292.135,297.09,292.135,302.455 M325.392,305.317c0-5.009,1.073-9.299,2.862-12.876
		c1.785-3.217,4.291-6.079,7.149-7.511c2.503-1.787,5.725-3.218,8.941-3.934c2.858-0.355,5.72-1.071,8.225-1.071
		c3.575,0,6.795,0.716,9.295,1.071c2.505,1.073,4.649,1.79,5.725,2.503c-0.358,2.146-1.075,4.293-1.79,6.797h-0.713
		c-3.222-3.935-7.509-6.078-11.804-6.078c-4.288,0-8.581,1.784-11.443,5.36c-3.216,3.579-5.006,8.583-5.006,14.662
		c0,5.366,1.79,10.369,4.295,13.589c2.86,3.221,7.149,5.004,12.514,5.004c1.787,0,4.29-0.356,6.794-1.071
		c2.146-1.071,4.291-2.145,5.723-3.574l1.072,0.356l-1.787,5.721c-3.219,1.431-8.227,2.146-13.589,2.146
		c-5.364,0-10.016-1.072-14.306-2.858c-3.934-1.791-7.151-4.295-9.295-7.512C326.465,312.824,325.392,308.889,325.392,305.317
		 M361.509,268.484l-8.581,9.295h-6.438l-8.584-9.295h3.933l7.868,4.291l7.87-4.291H361.509z M374.382,325.339v-43.983h26.462
		c0,0.714-0.717,1.787-0.717,2.862c0,0.712,0.359,1.784,0.359,2.858c-4.65-0.715-9.297-1.074-13.59-1.074h-2.145v13.59h8.94
		c2.502,0,5.005,0,6.435-0.359v1.075c0,0.715-0.358,1.431-0.358,2.146c0.358,0.712,0.358,1.786,0.358,2.502
		c-2.146-0.358-5.005-0.716-8.226-0.716h-7.149v16.45c5.362,0,10.728-0.36,16.092-0.716c-0.357,1.43-0.717,2.146-0.717,3.218
		c0,0.36,0.359,1.432,0.359,2.146H374.382z M406.924,325.696v-44.34h17.52c3.219,0,5.721,0.356,7.512,0.714
		c1.43,0.717,3.215,1.788,4.288,2.86c2.148,1.79,3.22,4.294,3.22,7.154c0,3.575-1.071,6.436-3.575,7.867
		c-2.146,1.787-5.007,3.574-7.511,3.933c1.787,2.504,3.578,5.365,5.008,7.51c1.428,2.144,3.216,4.647,5.007,6.794
		c1.429,2.503,3.573,5.365,5.362,7.508c-2.502-0.356-4.651-0.356-6.436-0.356c-1.789,0-4.293,0-6.438,0.356
		c-2.146-3.219-4.65-7.508-7.511-11.8c-2.86-4.646-5.007-7.508-5.719-8.939h-0.358v20.739c-2.146-0.356-3.575-0.356-5.006-0.356
		C410.855,325.339,409.066,325.339,406.924,325.696 M417.293,302.094c7.15,0.36,11.441-2.502,11.441-8.937
		c0-3.221-1.072-5.725-2.504-6.438c-1.431-1.074-3.574-1.79-6.436-1.79h-2.502V302.094z M444.825,325.696
		c2.147-11.086,4.649-26.104,7.153-44.34h1.43h1.431h2.86c3.934,7.509,9.297,17.521,16.451,30.395
		c7.865-14.302,13.584-24.674,16.444-30.395h2.862h2.862c1.431,9.654,2.503,17.164,3.218,22.885
		c0.717,5.722,1.787,12.875,3.219,21.455c-1.79-0.356-3.934-0.356-5.724-0.356c-2.143,0-4.289,0-6.076,0.356
		c0-2.862-0.361-6.438-0.716-9.298c0-3.574-0.716-6.791-0.716-9.654c-0.358-2.859-1.071-6.792-1.431-11.084
		c-5.72,10.013-11.085,20.382-15.733,29.68h-1.072h-1.431c-1.79-3.577-3.574-6.438-4.648-8.941
		c-1.432-2.502-2.502-4.647-3.219-6.077c-1.071-1.432-2.146-3.577-3.218-6.08c-1.073-2.146-2.506-5.008-4.289-8.223
		c-0.359,3.215-1.075,6.794-1.432,10.37c-0.719,3.933-1.073,7.508-1.073,10.368c0,3.219-0.717,6.437-0.717,8.939h-1.43
		c-0.357,0-1.432-0.356-1.787-0.356C446.612,325.339,445.541,325.696,444.825,325.696 M504.188,325.339l19.665-44.698h1.433h1.071
		h2.862l19.667,44.698h-3.578h-2.858h-2.147h-3.575c-1.433-3.935-3.221-8.583-5.005-13.23h-16.093
		c-1.072,2.501-2.145,4.289-2.503,5.363c-0.359,1.072-1.073,2.503-1.431,4.29c-0.358,1.791-1.072,2.862-1.072,3.577h-1.79h-1.784
		h-1.074H504.188z M530.289,307.817l-6.437-14.659l-6.795,14.659H530.289z M518.848,277.063l10.011-7.507
		c1.073-0.717,2.147-1.79,3.935-1.79c2.146,0,3.219,0.718,3.219,2.862c0,1.071-0.718,2.502-2.146,2.86l-10.726,3.574H518.848z
		 M553.53,281.356h5.009h5.008v20.026c3.215-3.221,6.437-6.795,10.008-10.729c3.578-3.934,6.438-7.154,8.229-9.298h3.934h3.574
		v0.714c-10.011,9.655-16.092,15.733-17.877,17.882l18.949,25.031v0.713c-2.144-0.356-4.292-0.356-5.718-0.356
		c-1.432,0-3.221,0-5.365,0.356l-15.733-20.739v20.739h-2.506c-0.716,0-1.787-0.356-2.502-0.356c-1.431,0-3.219,0.356-5.009,0.356
		V281.356z"/>
</g>
</svg></li></a>
            <a href="#Prodej"><li><span>Prodej</span></li></a>
            <a href="#Kontakt"><li><span>Kontakt</span></li></a>
        </ul>
    </div>
	<div id="mobile_menu" v-if="!isVisible">
	<div id="mobile_menu_top" class="animated slideInDown fast">
		<button v-on:click="menu=!menu"><svg v-if="!menu" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="45px" height="45px" viewBox="0 0 45 45" enable-background="new 0 0 45 45" xml:space="preserve">
<g>
	<line fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" x1="6" y1="23.5" x2="38" y2="23.5"/>
	<line fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" x1="6" y1="10.5" x2="38" y2="10.5"/>
	<line fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" x1="6" y1="37.5" x2="38" y2="37.5"/>
</g>
</svg><svg v-if="menu" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="45px" height="45px" viewBox="0 0 45 45" enable-background="new 0 0 45 45" xml:space="preserve">
<g>
	<line fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" x1="8.375" y1="9" x2="36.625" y2="36"/>
	<line fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" x1="8.375" y1="36" x2="36.625" y2="9"/>
</g>
</svg></button>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="595px" height="410px" viewBox="0 0 595 410.022" enable-background="new 0 0 595 410.022" xml:space="preserve">
<g>
	<polygon fill="#FFFFFF" points="4.637,293.87 264.961,83.61 264.961,164.067 101.541,293.87 	"/>
	<polygon fill="#FFFFFF" points="264.602,180.516 264.602,269.555 150.89,269.196 	"/>
	<path fill="#FFFFFF" d="M431.596,211.986c14.304,0,28.964-0.36,40.406-3.579l-0.356,60.433h-81.887
		c-166.639-4.646-165.922-177.721,0-184.513h31.825v40.765l50.418-40.408v56.142c-10.727-2.503-27.535-4.293-40.406-4.293
		c-47.918,0-81.531,17.522-81.531,38.264C350.064,195.892,384.752,212.342,431.596,211.986"/>
	<path fill="#FFFFFF" d="M123.712,325.339l20.025-44.698h1.071h1.431h2.503l19.667,44.698h-3.574h-2.861h-2.145h-3.576
		c-1.431-3.935-2.859-8.583-5.008-13.23h-16.09c-1.072,2.501-1.786,4.289-2.503,5.363c-0.356,1.072-1.074,2.503-1.431,4.29
		c-0.356,1.791-1.071,2.862-1.071,3.577h-1.788h-1.789h-1.072H123.712z M149.816,307.817l-6.079-14.659l-6.795,14.659H149.816z
		 M170.556,308.889v-27.533h5.008h5.364v25.032c0,5.006,0.713,8.939,2.143,11.084c2.147,2.858,5.724,4.648,10.016,4.648
		c2.502,0,4.647-0.358,6.078-0.716c1.431-0.714,2.861-2.146,4.29-3.575c2.146-2.502,3.576-7.509,3.576-14.663v-21.811h1.073h1.431
		h1.431h1.786v23.601c0,8.227-1.786,13.945-5.363,16.448c-1.072,1.072-2.503,2.148-3.934,2.86c-1.429,0.718-3.576,1.074-5.361,1.788
		c-1.79,0.358-4.293,0.358-6.439,0.358c-7.15,0-13.231-1.787-16.804-5.007C171.987,318.544,170.556,314.254,170.556,308.889
		 M229.202,325.339v-39.693c-4.65,0-9.3,0.356-13.232,1.074v-0.718c0-0.356,0.357-0.713,0.357-0.713v-0.715V283.5v-1.071
		c0-0.358-0.357-0.716-0.357-1.072h36.116c0,0.714-0.357,2.144-0.357,3.219c0,0.715,0.357,1.787,0.357,2.146
		c-6.08-0.718-10.014-1.074-12.516-1.074v39.693h-3.219h-2.502h-2.146H229.202z M249.94,303.884v0.714
		c0,5.723,1.788,10.369,4.65,13.59c3.216,3.217,6.438,5.365,10.726,6.795c3.935,1.07,7.867,1.429,10.729,1.429
		c8.223,0,15.018-2.146,20.383-6.081c5.363-3.933,7.866-9.653,7.866-16.805c0-4.293-1.072-8.227-3.22-11.801
		c-1.786-3.218-5.005-6.436-8.94-8.582c-3.933-2.146-9.297-3.218-15.018-3.218c-6.08,0-11.085,1.431-15.377,3.574
		c-3.934,2.503-7.149,5.365-8.939,8.941C251.014,296.018,249.94,299.952,249.94,303.884 M292.135,302.455
		c0,4.289-1.43,9.296-3.574,13.943c-2.146,4.293-6.437,6.436-12.159,6.436c-2.503,0-5.005-0.713-7.15-2.143
		c-1.788-1.073-3.578-3.219-5.007-5.724c-1.431-2.503-2.145-5.718-2.145-9.295c0-3.934,0.358-7.511,1.431-10.728
		c0.714-3.22,2.861-6.08,5.004-7.868c2.503-2.146,5.364-3.219,8.939-3.219c4.293,0,8.226,1.788,10.729,5.007
		C290.705,292.441,292.135,297.09,292.135,302.455 M325.392,305.317c0-5.009,1.073-9.299,2.862-12.876
		c1.785-3.217,4.291-6.079,7.149-7.511c2.503-1.787,5.725-3.218,8.941-3.934c2.858-0.355,5.72-1.071,8.225-1.071
		c3.575,0,6.795,0.716,9.295,1.071c2.505,1.073,4.649,1.79,5.725,2.503c-0.358,2.146-1.075,4.293-1.79,6.797h-0.713
		c-3.222-3.935-7.509-6.078-11.804-6.078c-4.288,0-8.581,1.784-11.443,5.36c-3.216,3.579-5.006,8.583-5.006,14.662
		c0,5.366,1.79,10.369,4.295,13.589c2.86,3.221,7.149,5.004,12.514,5.004c1.787,0,4.29-0.356,6.794-1.071
		c2.146-1.071,4.291-2.145,5.723-3.574l1.072,0.356l-1.787,5.721c-3.219,1.431-8.227,2.146-13.589,2.146
		c-5.364,0-10.016-1.072-14.306-2.858c-3.934-1.791-7.151-4.295-9.295-7.512C326.465,312.824,325.392,308.889,325.392,305.317
		 M361.509,268.484l-8.581,9.295h-6.438l-8.584-9.295h3.933l7.868,4.291l7.87-4.291H361.509z M374.382,325.339v-43.983h26.462
		c0,0.714-0.717,1.787-0.717,2.862c0,0.712,0.359,1.784,0.359,2.858c-4.65-0.715-9.297-1.074-13.59-1.074h-2.145v13.59h8.94
		c2.502,0,5.005,0,6.435-0.359v1.075c0,0.715-0.358,1.431-0.358,2.146c0.358,0.712,0.358,1.786,0.358,2.502
		c-2.146-0.358-5.005-0.716-8.226-0.716h-7.149v16.45c5.362,0,10.728-0.36,16.092-0.716c-0.357,1.43-0.717,2.146-0.717,3.218
		c0,0.36,0.359,1.432,0.359,2.146H374.382z M406.924,325.696v-44.34h17.52c3.219,0,5.721,0.356,7.512,0.714
		c1.43,0.717,3.215,1.788,4.288,2.86c2.148,1.79,3.22,4.294,3.22,7.154c0,3.575-1.071,6.436-3.575,7.867
		c-2.146,1.787-5.007,3.574-7.511,3.933c1.787,2.504,3.578,5.365,5.008,7.51c1.428,2.144,3.216,4.647,5.007,6.794
		c1.429,2.503,3.573,5.365,5.362,7.508c-2.502-0.356-4.651-0.356-6.436-0.356c-1.789,0-4.293,0-6.438,0.356
		c-2.146-3.219-4.65-7.508-7.511-11.8c-2.86-4.646-5.007-7.508-5.719-8.939h-0.358v20.739c-2.146-0.356-3.575-0.356-5.006-0.356
		C410.855,325.339,409.066,325.339,406.924,325.696 M417.293,302.094c7.15,0.36,11.441-2.502,11.441-8.937
		c0-3.221-1.072-5.725-2.504-6.438c-1.431-1.074-3.574-1.79-6.436-1.79h-2.502V302.094z M444.825,325.696
		c2.147-11.086,4.649-26.104,7.153-44.34h1.43h1.431h2.86c3.934,7.509,9.297,17.521,16.451,30.395
		c7.865-14.302,13.584-24.674,16.444-30.395h2.862h2.862c1.431,9.654,2.503,17.164,3.218,22.885
		c0.717,5.722,1.787,12.875,3.219,21.455c-1.79-0.356-3.934-0.356-5.724-0.356c-2.143,0-4.289,0-6.076,0.356
		c0-2.862-0.361-6.438-0.716-9.298c0-3.574-0.716-6.791-0.716-9.654c-0.358-2.859-1.071-6.792-1.431-11.084
		c-5.72,10.013-11.085,20.382-15.733,29.68h-1.072h-1.431c-1.79-3.577-3.574-6.438-4.648-8.941
		c-1.432-2.502-2.502-4.647-3.219-6.077c-1.071-1.432-2.146-3.577-3.218-6.08c-1.073-2.146-2.506-5.008-4.289-8.223
		c-0.359,3.215-1.075,6.794-1.432,10.37c-0.719,3.933-1.073,7.508-1.073,10.368c0,3.219-0.717,6.437-0.717,8.939h-1.43
		c-0.357,0-1.432-0.356-1.787-0.356C446.612,325.339,445.541,325.696,444.825,325.696 M504.188,325.339l19.665-44.698h1.433h1.071
		h2.862l19.667,44.698h-3.578h-2.858h-2.147h-3.575c-1.433-3.935-3.221-8.583-5.005-13.23h-16.093
		c-1.072,2.501-2.145,4.289-2.503,5.363c-0.359,1.072-1.073,2.503-1.431,4.29c-0.358,1.791-1.072,2.862-1.072,3.577h-1.79h-1.784
		h-1.074H504.188z M530.289,307.817l-6.437-14.659l-6.795,14.659H530.289z M518.848,277.063l10.011-7.507
		c1.073-0.717,2.147-1.79,3.935-1.79c2.146,0,3.219,0.718,3.219,2.862c0,1.071-0.718,2.502-2.146,2.86l-10.726,3.574H518.848z
		 M553.53,281.356h5.009h5.008v20.026c3.215-3.221,6.437-6.795,10.008-10.729c3.578-3.934,6.438-7.154,8.229-9.298h3.934h3.574
		v0.714c-10.011,9.655-16.092,15.733-17.877,17.882l18.949,25.031v0.713c-2.144-0.356-4.292-0.356-5.718-0.356
		c-1.432,0-3.221,0-5.365,0.356l-15.733-20.739v20.739h-2.506c-0.716,0-1.787-0.356-2.502-0.356c-1.431,0-3.219,0.356-5.009,0.356
		V281.356z"/>
</g>
</svg>
	</div>
	<transition name="fade">
	<div id="mobile_menu_bottom" v-if="menu">
		<ul @click="menu=!menu">
			<li><a href="#O_nas">O nás</a></li>
			<li><a href="#Sluzby">Služby</a></li>
			<li><a href="#Prodej">Prodej</a></li>
			<li><a href="#Kontakt">Kontakt</a></li>
		</ul>
	</div>
	</transition>
	</div>
</div>
</template>

<script>
var pagetop, menu, yPos;
function yScroll(){
	menu = document.getElementById('header_menu');
	if (menu) {
		yPos = window.pageYOffset;
	if(yPos > 50){
		menu.style.padding = "20px 0px 10px";
	} else {
		menu.style.padding = "70px 0px 30px";
	}	
	}
}
window.addEventListener("scroll", yScroll);


export default {
	name: 'HeaderMenu',
	data: function(){
		return{
			menu: false
		}
	},
	computed:{
		isVisible(){
            return this.$store.state.modalVisible
        },
	}

}
</script>

<style scoped>
#header_menu{
    width: 100%;
    position: fixed;
    z-index: 20;
    display: block;
    padding: 70px 0px 30px 0px;
    font-weight: 300;
	font-size: 1.2rem;
	background: rgba(41, 40, 46, 0.9);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	transition: padding 300ms;
}

ul{
    width: 90%;
    margin: 0 auto
}

ul li{
    display: inline-block;
    width: 20%;
    text-align: center;
    height: 45px;
    float: left;
}

a:hover{
    color: #910C0C
}

ul li span::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #910C0C;
    transition: width .5s;
}

ul li:hover span::after {
    width: 100%;
}

ul li:hover > svg g polygon, ul li:hover > svg g path{
    fill: #910C0C
}

a{
    line-height: 60px;
    text-decoration: none;
    color: white;
}

a svg{
    height: 90px;
    width: 120px;
    padding: 10px;
    margin-top: -20px;
	transition: transform 300ms;
}

a svg:hover{
    transform: scale(1.1)
}

.hidden_menu{
	opacity: 0;
	translate: opacity 1s;
}

#mobile_menu_top{
	background: rgba(41, 40, 46, 0.9);
	backdrop-filter: blur(5px);
	display: none;
	z-index: 1000;
	width: 100%;
	height: 60px;
}

#mobile_menu_top button{
	width: 35px;
	height: 35px;
	background: none;
	font-size: 2rem;
	padding: 0px;
	border: none;
	color: #fff;
	display: inline-block;
	margin: 1rem;
	border-radius: 3px;
	float: right;
}

#mobile_menu_top button svg{
	width: 100%;
	height: 80%;
	margin: 0
}

#mobile_menu_top svg{
	display: block;
	height: 45px;
	width: 85px;
	display: inline-block;	
	float: left;
	margin: .6rem;
	
}

#mobile_menu_bottom{
	position: fixed;
	display: none;
	margin-top: 60px;
	background: rgba(145, 12, 12, 0.9);
	backdrop-filter: blur(5px);
	z-index: 1000;
	width: 100%;
	z-index: 999;
}

#mobile_menu_bottom ul li{
	display: block;
	width: 100%;
	text-align: left;
	font-size: 1.5rem;
	font-weight: 100;
}
#mobile_menu_bottom ul li a{
	display: block;
	width: 100%;
	text-align: left;
	font-size: 1.5rem;
	font-weight: 100;
}

#mobile_menu_bottom ul li:hover > a{
	color: #000
}

@media (max-width: 700px) {
  #header_menu{
	  display: none
  }
  #mobile_menu_top{
	position: fixed;
	display: block;
  }
  #mobile_menu_bottom{
	display: block;
  }
}

</style>


