<template>
  <div id="app" v-if="count==count">
    <modal></modal>
    <header-menu></header-menu>
    <div class="animated fadeIn fast" v-bind:style="{ backgroundImage: 'url(' + texts.hero_photo.url + ')' }" id="hero_bg"></div>
    <div class="container">
      <svg></svg>
      <div id="hero" class="animated fadeIn delay-1s fast">
        <div class="row" id="hero_claim">
          <div class="six columns">
            <h1 class="animated fadeIn delay-1s fast redish">{{texts.hero_headline}}<br> ______ <br></h1><h1 class="animated delay-1s slideInUp faster">{{texts.hero_subheader}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="six columns"><br></div>
          <div class="five columns" id="hero_text">
            <div class="animated delay-1s slideInUp fast">
              <p>{{texts.hero_text}}</p>
            </div>
            
            <button href="#" @click="open_sluzby()" class="animated delay-2s fadeInUp faster prime_button">Naše služby</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div id="O_nas" class="six columns section">
          <h1 class="animated fadeIn delay-1s fast underline_header">O nás</h1>
          <p class="animated fadeIn delay-1s fast paragraph">
            {{texts.about_us_text}}
          </p>
        </div>
        <div class="six columns section">
          <h1 class="animated fadeIn delay-1s fast underline_header">Aktuality</h1>
          <p class="paragraph animated fadeIn delay-1s fast" v-if="!news">Žádné aktuality</p>
          <ul class="animated fadeIn delay-1s fast" id="aktuality">
            <li v-for="(news_item) in news" :key="news_item.id">
              <div>{{news_item.date}}</div><br>
              <p>{{news_item.text}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="full columns section" id="Sluzby">
          <h1 class="animated fadeIn delay-1s fast underline_header">
            Služby
          </h1>

            <div v-for="(service_category) in service_categories" :key="service_category.id">
              <div class="animated fadeIn delay-1s fast category_header" v-if="service_category.count>0">
                <h5 class="category_name">{{service_category.name}}</h5>
                <p class="paragraph">{{service_category.description}}</p>
              </div>
                <div class="items_listing">
                  <service-card v-for="(item) in filter_service_items(service_category.id)" :key="item.id" v-bind:sluzba="item"></service-card>
                </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="full columns section" id="Prodej">
          <h1 class="animated fadeIn delay-1s fast underline_header">Prodej</h1>
            <div v-for="(sale_category) in sale_categories" :key="sale_category.id">
              <div  class="animated fadeIn delay-1s fast category_header" v-if="sale_category.count>0">
                <h5 class="category_name">{{sale_category.name}}</h5>
                <p class="paragraph">{{sale_category.description}}</p>
              </div>
                <div class="items_listing">
                  <car-card v-for="(item) in filter_sale_items(sale_category.id)" :key="item.id" v-bind:car="item"></car-card>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        <div class="four columns section" id="Kontakt">
          <h1 class="underline_header">Kontakt</h1>
          <p id="contact_text">{{texts.contact}}</p>
        </div>
        <div class="four columns">
          <iframe class="mapa bw" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.9081481857793!2d15.563535915914084!3d49.410642669616145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d1a53c8eba56d%3A0x6f1ac6b6dc56fbe7!2zQXV0byDEjGVybcOhayAtIG5lesOhdmlzbMO9IEJNVyBzZXJ2aXM!5e0!3m2!1scs!2scz!4v1540434447703" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
        <div class="four columns">
          <iframe class="mapa" src="https://www.google.com/maps/embed?pb=!4v1540433800705!6m8!1m7!1sCAoSLEFGMVFpcE56SXlxZUxrdzVSYnZaTzZHeFIyeVJOM1M1STYwYnpISlIwNXlw!2m2!1d49.410657674104414!2d15.565919064849904!3f239.89989!4f0!5f0.7820865974627469" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="row">
        <div class="full columns">
          <p class="footer">© 2018 Auto Čermák, Všechna práva vyhrazena</p>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import HeaderMenu from './components/HeaderMenu'
import ServiceCard from './components/ServiceCard'
import CarCard from './components/CarCard'
import Modal from './components/Modal'

export default {
  name: 'App',
  components: {HeaderMenu, Modal, ServiceCard, CarCard},
  created(){
    if (this.$route.params.what) {
      this.$store.commit('TOGGLE_MODAL')
    }
    this.$store.dispatch('LOAD_TEXTS')
    this.$store.dispatch('LOAD_SALE_CATEGORIES')
    this.$store.dispatch('LOAD_SALE_ITEMS')
    this.$store.dispatch('LOAD_SERVICES_CATEGORIES')
    this.$store.dispatch('LOAD_SERVICES_ITEMS')
    this.$store.dispatch('LOAD_NEWS')
  },
  mounted(){
    console.log('new')
  },
  computed:{
    sluzby(){
      return this.$store.state.sluzby
    },
    sale_categories(){
      return this.$store.state.sale_categories
    },
    sale_items(){
      return this.$store.state.sale_items
    },
    texts(){
      return this.$store.state.texts
    },
    news(){
      return this.$store.state.news
    },
    service_items(){
      return this.$store.state.service_items
    },
    service_categories(){
      return this.$store.state.service_categories
    },
    count(){
      if (this.$route.fullPath == '/') {
        this.$store.commit('MODAL',false)
      }
      if(this.$route.name != 'app'){
        this.$store.commit('MODAL',true)
      }
      if(this.$route.name == null){
        this.$store.commit('MODAL',false)
      }
      console.log(this.$route.name)
      return false
    }
  },
  methods:{
    filter_sale_items(id){
      return this.sale_items.filter(item => item.category == id)
    },
    filter_service_items(id){
      return this.service_items.filter(item => item.category == id)
    },
    open_sluzby(){
      var elmnt = document.getElementById("Sluzby");
      elmnt.scrollIntoView();
    }
  }
  
}
</script>


<style>
body{
  background: #29282E;
  color: #fff
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* Animation */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s

}
.fade-enter, .fade-leave-to {
  transform: translateY(-500px)
}


#hero_bg{
  height: 70vh;
  width: 70vw;
  margin:25vh 15vw 0vh;
  position: absolute;
  background: no-repeat;
  background-size:60%;
  background-position: center;
  z-index: -1;
}


#hero{
  margin: 40px 0px 0px 0px;
  padding: 50px;
}

.redish{
  color: #910C0C!important;  
}

#hero_claim h1{
  color: #fff;
  font-weight: 300;
  line-height: 4rem;
  font-size: 2.6rem;
  text-align: right;
  white-space: pre-line
  
}

#hero_text{
  margin-top: 30px;
}

#hero_text p{
  color: #fff;
  line-height: 3.5rem;
  font-size: 1.2rem;
  font-weight: 100
}

.prime_button{
  background: none;
  padding: 10px 35px;
  border: 1px solid #910C0C;
  color: #910C0C;
  border-radius: 33px;
  font-size: 1.2rem;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  transition: transform 300ms;
  transition: box-shadow 300ms
}

.prime_button:hover{
  background: #910C0C;
  color: #000000;
  transform: scale(1.05);
  -webkit-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  -moz-box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);
  box-shadow: 7px 7px 38px -7px rgba(0,0,0,0.5);

}

.underline_header{
  color: #fff;
  font-weight: 300;
  line-height: 4rem;
  font-size: 2.6rem;
  margin: 0px 0px 50px;
}

.underline_header::before{
  content: "___";
  display: block;
  color: #910C0C
}

.paragraph{
  color: #fff;
  line-height: 3.5rem;
  font-size: 1.2rem;
  font-weight: 100;
}

#aktuality li{
  list-style: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 100
}

#aktuality li div{
  font-size: 0.8rem;
  line-height: 0.7rem;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 33px;
  margin: 0px 0px 10px 0px;
}

#aktuality li::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #fff;
  margin: 25px 0px
}

#aktuality li:last-of-type::after{
  display: none;
}

.section{
  padding-top: 120px
}

.category_name{
  margin: 60px 0px 0px;
  font-size: 2rem;
  color: #910C0C;
}

.items_listing{
  column-count: 2;
  column-gap: 4.5rem;
  margin-top: -30px
}

#contact_text{
  white-space: pre-line;
  font-weight: 100;
  font-size: 1.3rem
}

.mapa{
  width: 100%;
  margin-top: 180px;
}

.bw{
  filter: grayscale(1)
}
.footer{
    display: block;
    width: 100%;
    text-align: center;
    padding: 50px 0px 0px 0px;
    font-size: .8rem;
    font-weight: 100
  }

@media (max-width: 700px) {
  .items_listing{
  column-count: 1;
  }
  .underline_header{
    padding-top: 20px; 
  }
  .section{
    padding-top:20px
  }
  .category_name{
    padding: 0px
  }
  .mapa{
    margin-top: 10px
  }
  #hero{
    margin: 20px 0px 0px 0px;
    padding: 0px;
  }
  #hero_claim h1{
  text-align: left;
  }
  #hero_bg{
    width: 100%;
    height: 80%;
    margin: 10% 0px 0px;
    background-size: 100%
  }
}

</style>
